import React, { useState } from 'react'
import { Row, Col, Button, Dropdown } from 'react-bootstrap'
import classnames from 'classnames'
import { TaskSort } from '../../objects/task'

export interface TasksHeaderProps {
  sortBy?: string
  onSort?: Function
  onFilter: () => void
  filtered: boolean
  completed?: boolean
}

const sortOptions = [
  { value: TaskSort.DueDate, label: 'Sort by due date' },
  { value: TaskSort.Assignee, label: 'Sort by assignee' }
]

export const TasksHeader = (props: TasksHeaderProps): JSX.Element => {
  const { onSort, onFilter, filtered, completed } = props
  const heading = completed ? 'Completed Tasks' : 'Tasks'
  const [selectedLabel, setSelectedLabel] = useState(sortOptions[0].label)

  const handleSort = (_name: string, value: string) => {
    onSort(value)
  }

  const handleSelect = (eventKey) => {
    const selectedOption = sortOptions.find(
      (option) => option.value === eventKey
    )
    setSelectedLabel(selectedOption?.label)
    handleSort('tasks-pick-list', eventKey)
  }

  return (
    <div className='container-fluid'>
      <Row className='d-flex flex-row justify-content-normal align-items-center tasks-header px-sm-0'>
        <Col xs={6} sm={true} className='p-0'>
          <h2 className='mb-0'>{heading}</h2>
        </Col>
        <Col
          xs={6}
          sm={true}
          className={classnames('tasks-header__filter pe-0', {
            'pe-sm-4': !completed
          })}>
          <Button variant='' onClick={onFilter}>{`Show ${
            filtered ? 'all' : 'my'
          } tasks`}</Button>
        </Col>
        {!completed && (
          <Col xs={12} className='tasks-header__sort pe-0'>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                variant=''
                id='dropdown-basic'
                className='d-flex justify-content-between align-items-center text-start tasks-header__dropdown-toggle'>
                {selectedLabel}
                <i className='bi bi-chevron-down'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortOptions.map((option, index) => (
                  <Dropdown.Item
                    eventKey={option.value}
                    key={option.value + index}
                    className='tasks-header__dropdown-item'>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>
    </div>
  )
}
