import React from 'react'
import { connect } from 'react-redux'
import NetWorthDisclosureText from './netWorthDisclosurerText'
import InvestmentDisclosure from './investmentDisclosureText'
import FinancialGoalDisclosure from './financialGoalDisclosureText'
import { eMoneyDisclosure } from './emoneyDisclosure'
import { financewareDisclosure } from './financewareDisclosure'
import { mgpDisclosure } from './mgpDisclosure'
import { GlobalState } from '../../reducers'
import { history, AppDispatch } from '../../store'
import { FundedScoreObj } from '../../objects/financialGoal'
import { marketplaceOfferRequestInterface } from '../../objects/marketplaceOffers'
import { UserObj } from '../../objects/user'
import * as householdActions from '../../actions/household.action'
import * as marketplaceActions from '../../actions/marketplaceOffers.actions'
import * as userActions from '../../actions/user.action'
import { ReactComponent as PrevIcon } from '../../assets/images/icons/previous.svg'
import { marketplaceOfferDisclosures } from './marketplaceOffersDisclosure'

type refName =
  | 'account'
  | 'investment'
  | 'planningSoftware'
  | 'marketplaceOffer'

type refMatchObj = { [K in refName]: React.RefObject<HTMLDivElement> }

export interface DisclosuresProps {
  dispatch: AppDispatch
  user: UserObj
  investmentDisclosure: string
  marketplaceOffers: marketplaceOfferRequestInterface
  fundedScore: FundedScoreObj[]
  isFinlifeInstitution: boolean
  location?: any
  history?: any
}

interface DisclosuresState {
  referrer: {
    page?: string
    prevPath?: string
    section?: refName
  }
}

export class Disclosures extends React.Component<
  DisclosuresProps,
  DisclosuresState
> {
  private accountsRef: React.RefObject<HTMLDivElement>
  private investmentRef: React.RefObject<HTMLDivElement>
  private planningSoftwareRef: React.RefObject<HTMLDivElement>
  private marketplaceOffersRef: React.RefObject<HTMLDivElement>

  private mounted: boolean

  constructor(props) {
    super(props)
    this.mounted = false

    this.accountsRef = React.createRef()
    this.investmentRef = React.createRef()
    this.planningSoftwareRef = React.createRef()
    this.marketplaceOffersRef = React.createRef()

    this.state = {
      referrer: this.props.location.state
    }
  }

  public async componentDidMount() {
    const { user, dispatch, history } = this.props
    const { referrer } = this.state
    const hash = history.location.hash

    if (hash) {
      this.scrollToRef(hash.substring(1))()
    }
    this.mounted = true
    this.syncSessionStorage()

    if (!user.id) {
      await dispatch(userActions.getUser())
    }
    if (this.mounted) {
      if (!user.householdId) {
        await dispatch(householdActions.getHousehold())
      }
      await dispatch(marketplaceActions.getMarketplaceOffers(user.householdId))
      if (referrer && referrer.section) {
        this.scrollToRef(referrer.section)()
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public getPlanningDisclosure = () => {
    const { fundedScore } = this.props
    const source = fundedScore[0] && fundedScore[0].source
    switch (source) {
      case 'gdx360':
        return financewareDisclosure()
      case 'emoney':
        return eMoneyDisclosure()
      case 'moneyguidepro':
        return mgpDisclosure()
      default:
        return null
    }
  }

  public syncSessionStorage = () => {
    if (this.state.referrer) {
      sessionStorage.setItem(
        'disclosuresReferrer',
        JSON.stringify(this.state.referrer)
      )
    } else if (sessionStorage.getItem('disclosuresReferrer')) {
      const stateFromLocalStorage = JSON.parse(
        sessionStorage.getItem('disclosuresReferrer')
      )
      this.setState((prevState) => ({
        referrer: { ...prevState.referrer, ...stateFromLocalStorage }
      }))
    }
  }

  public navBack = () => {
    const { referrer } = this.state
    const { history } = this.props
    if (referrer && referrer.prevPath) {
      history.push(referrer.prevPath)
    } else {
      history.goBack()
    }
  }

  public scrollToRef = (refName: refName) => () => {
    const refMatch: refMatchObj = {
      account: this.accountsRef,
      investment: this.investmentRef,
      planningSoftware: this.planningSoftwareRef,
      marketplaceOffer: this.marketplaceOffersRef
    }
    const ref = refMatch[refName]
    ref && ref.current && ref.current.scrollIntoView()
  }

  public render() {
    const {
      investmentDisclosure,
      isFinlifeInstitution,
      marketplaceOffers
    } = this.props
    const planningDisclosure = this.getPlanningDisclosure()

    const offerDisclosures = marketplaceOfferDisclosures(marketplaceOffers)

    return (
      <div className='disclosures'>
        <div className='disclosures__sidebar'>
          <div className='disclosures__back' onClick={this.navBack}>
            <PrevIcon />
            Back
          </div>
          <div className='disclosures__nav'>
            <div
              className='disclosures__nav-link'
              onClick={this.scrollToRef('account')}>
              Accounts
            </div>
            <div
              className='disclosures__nav-link'
              onClick={this.scrollToRef('investment')}>
              Investments
            </div>
            <div
              className='disclosures__nav-link'
              onClick={this.scrollToRef('planningSoftware')}>
              Planning Software
            </div>
            {offerDisclosures?.length > 0 && (
              <div
                className='disclosures__nav-link'
                onClick={this.scrollToRef('marketplaceOffer')}>
                Resources
              </div>
            )}
          </div>
        </div>
        <div className='disclosures__body'>
          <h1 className='disclosures__page-title'>
            Disclosures and Disclaimers
          </h1>
          <div id='accounts' ref={this.accountsRef}>
            <NetWorthDisclosureText extraDisclosure={investmentDisclosure} />
          </div>
          <div id='investment' ref={this.investmentRef}>
            <InvestmentDisclosure isPFM={!isFinlifeInstitution} />
          </div>
          <div id='financialPlanning' ref={this.planningSoftwareRef}>
            <FinancialGoalDisclosure extraDisclosure={planningDisclosure} />
          </div>
          {offerDisclosures?.length > 0 && (
            <div id='marketplaceOffers' ref={this.marketplaceOffersRef}>
              <h2 className='disclosures__section-title'>Resources</h2>
              {offerDisclosures}
            </div>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (
  store: GlobalState,
  dispatch: AppDispatch
): DisclosuresProps => {
  return {
    dispatch,
    history,
    user: store.user,
    marketplaceOffers: store.marketplaceOffers,
    investmentDisclosure: store.user.netWorthDisclosure,
    fundedScore: store.financialGoals.fundedScores,
    isFinlifeInstitution: store.user.isFinlifeInstitution,
    location: store.router.location
  }
}

export default connect(mapStateToProps)(Disclosures)
