import React from 'react'

import { ApolloProvider, Query } from 'react-apollo'
import { GQLClient } from '../../../app'
import { Link } from 'react-router-dom'

import ListGroup from 'react-bootstrap/ListGroup'

import {
  captionList,
  AllSetting,
  isAdjustable,
  ClientDesktopNotifications
} from '../../../objects/settings/clientAdvisor'
import { notificationsSubscriber } from '../../../helpers/notifications'
import { GetClientNotificationSettingsQuery } from '../../../helpers/queries'

const defaultType = ClientDesktopNotifications.map(
  (setting: AllSetting) => setting
)
/**
 *
 * @param response
 * @returns
 * return the status of each formatted for <List>
 */
export const getListRows = (response) => {
  if (!response?.data?.allClients?.nodes?.[0]) {
    return []
  }

  const {
    data: { allClients }
  } = response

  if (!response?.data?.allClients?.nodes?.[0]) {
    return []
  }

  const { desktopSubject, emailSubject, mobileSubject } = allClients.nodes[0]
  const clientSubjects = [...defaultType]

  if (
    !(
      desktopSubject.includes('MESSAGE') ||
      desktopSubject.includes('NEW_TASK_ASSIGNED') ||
      desktopSubject.includes('NEW_PDV_FILE') ||
      emailSubject.includes('MESSAGE') ||
      emailSubject.includes('NEW_TASK_ASSIGNED') ||
      mobileSubject?.includes('MESSAGE')
    )
  ) {
    return [{ label: 'All Notifications and Alerts are disabled' }]
  }

  return clientSubjects.reduce((uniq, setting: AllSetting) => {
    const captionName = captionList[setting]

    if (!captionName || !isAdjustable(setting)) {
      return uniq
    }
    const settingStatus = () => {
      const desktopEnabled = desktopSubject.includes(setting)
      const emailEnabled = emailSubject.includes(setting)
      const mobileEnabled = mobileSubject?.includes(setting)
      switch (true) {
        case desktopEnabled && emailEnabled && mobileEnabled:
          return 'mobile and email and web'
        case desktopEnabled && emailEnabled && !mobileEnabled:
          return 'email and web'
        case desktopEnabled && !emailEnabled && mobileEnabled:
          return 'mobile and web'
        case !desktopEnabled && emailEnabled && mobileEnabled:
          return 'mobile and email'
        case desktopEnabled && !emailEnabled && !mobileEnabled:
          return 'web'
        case !desktopEnabled && emailEnabled && !mobileEnabled:
          return 'email'
        case !desktopEnabled && !emailEnabled && mobileEnabled:
          return 'mobile'
        default:
          return 'no'
      }
    }

    const row = {
      label: `${captionName} has ${settingStatus()} notifications enabled`
    }
    return uniq.some((settingRow) => settingRow.label === row.label)
      ? uniq
      : [...uniq, row]
  }, [])
}

const NotificationSettingsDetails = (props) => {
  const { clientId } = props
  return (
    <ApolloProvider client={GQLClient}>
      <Query
        query={GetClientNotificationSettingsQuery}
        variables={{ clientId }}>
        {(response) => {
          notificationsSubscriber(response.subscribeToMore)
          return (
            <ListGroup variant='flush'>
              {getListRows(response).map((row) => (
                <ListGroup.Item key={row.label} className='border-0 p-0 py-2'>
                  <span className='rfont--body2 fw-bold'>{row.label}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )
        }}
      </Query>
    </ApolloProvider>
  )
}

const Notifications = (props: { clientId: string }): JSX.Element => {
  return (
    <div className='pb-3'>
      <div className='d-flex flex-row justify-content-between'>
        <h3 className='rfont--h3 text-bold text-dark mb-2'>Notifications</h3>
        <Link to='/my-profile/notifications'>
          <span className='profile-notif__link'>Edit Settings</span>
        </Link>
      </div>
      <NotificationSettingsDetails clientId={props.clientId} />
    </div>
  )
}

export default Notifications
