export interface TaskObj {
  id: string
  lifeActionTaskId?: string
  crmId: string
  subject: string
  dueDate: string
  createdDate: string
  completedDate: string
  complete: boolean
  status: string
  assigneeType: string
  assigneeNames: string
  includeInGuidebook: boolean
  primaryAssignee?: string
  secondaryAssignee?: string
  isClosed?: boolean
}

export interface TaskObjState {
  [key: string]: TaskObj
}

export interface TasksState {
  tasks: TaskObjState
  sortBy: TaskSort
  filtered: boolean
}

export enum TaskStatus {
  Completed = 'Completed',
  Not_Completed = 'In Progress'
}

export enum TaskSort {
  DueDate = 'due_date',
  Assignee = 'assignee'
}
