import '@babel/polyfill'
import 'whatwg-fetch'
import 'promise-polyfill'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import App from './app'
import { Provider } from 'react-redux'
import Store, { history } from './store'
// dayjs plugins
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(advancedFormat)

const renderApp = () => {
  ReactDOM.render(
    <Provider store={Store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

renderApp()

if (module.hot) {
  module.hot.accept(['./app'], () => {
    renderApp()
  })
}
